<template>
  <v-app>
    <div
      style="
        width: 120vw;
        height: 100vh;
        position: fixed;
        z-index: 99999999999;
        pointer-events: none;
        opacity: 0.01;
        transform: rotate(-45deg) translateX(-200px) translateY(-800px);
        font-weight: 900;
      "
    >
      <span style="margin: 20px; font-size: 40px" v-for="item in 500"
        >LG-{{ userStore ? userStore.name : "未登录" }}</span
      >
    </div>
    <drawer
      :drawer="drawer"
      :sidebarColor="sidebarColor"
      :sidebarTheme="sidebarTheme"
    ></drawer>
    <v-main>
      <div
        @click="drawer = false"
        v-if="drawer"
        class="position-absolute drawer-state"
      ></div>

      <app-bar
        v-if="$route.name != 'Profile'"
        background="bg-transparent"
        has-bg
        @drawer-toggle="drawer = $event"
        :toggle-active="drawer"
        :navbarFixed="navbarFixed"
        @toggleSettingsDrawer="toggleSettingsDrawer"
      ></app-bar>

      <app-bar
        v-else-if="$route.name == 'Profile'"
        background="bg-default"
        has-bg
        @drawer-toggle="drawer = $event"
        :toggle-active="drawer"
      ></app-bar>
      <app-bar
        v-else
        background="primary"
        linkColor="rgba(0,0,0,.6)"
        @drawer-toggle="drawer = $event"
        :toggle-active="drawer"
      ></app-bar>
      <div v-if="$route.meta.keepAlive">
        <keep-alive>
          <router-view :key="$route.path" />
        </keep-alive>
      </div>

      <router-view :key="$route.path" v-if="!$route.meta.keepAlive" />

      <uploader
        id="uploader"
        draggable="false"
        v-drag
        v-on:min="setMin"
        v-on:max="setMax"
        :canClick="uploaderCanClick"
      ></uploader>
    </v-main>
  </v-app>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import Drawer from "@/components/Drawer.vue";
import AppBar from "@/components/AppBar.vue";
import Uploader from "@/components/Uploader/Uploader.vue";
import $ from "jquery";
var _this;
export default {
  components: {
    FadeTransition,
    Drawer,
    AppBar,
    Uploader,
  },
  data() {
    return {
      drawer: null,
      showSettingsDrawer: false,
      sidebarColor: "default",
      sidebarTheme: "white",
      navbarFixed: true,

      uploaderCanClick: true,
    };
  },
  methods: {
    toggleSettingsDrawer(value) {
      this.showSettingsDrawer = value;
    },
    updateSidebarColor(value) {
      this.sidebarColor = value;
    },
    updateSidebarTheme(value) {
      this.sidebarTheme = value;
    },
    toggleNavbarPosition(value) {
      this.navbarFixed = value;
    },

    setMax() {
      $("#uploader").animate({
        left: `${document.documentElement.clientWidth - 610}px`,
        top: `${document.documentElement.clientHeight - 310}px`,
      });
      //$("#uploader").css("left","100vh");
    },
    setMin() {
      $("#uploader").animate({
        left: `${document.documentElement.clientWidth - 200}px`,
        top: `${document.documentElement.clientHeight - 100}px`,
      });
    },
  },
  directives: {
    drag: {
      // 指令的定义
      bind: (el) => {
        let odiv = el; //获取当前元素
        odiv.onmousedown = (e) => {
          //算出鼠标相对元素的位置
          let disX = e.clientX - odiv.offsetLeft;
          let disY = e.clientY - odiv.offsetTop;
          let oldX = e.clientX;
          let oldY = e.clientY;

          document.onmousemove = (e) => {
            //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            let left = e.clientX - disX;
            let top = e.clientY - disY;
            if (
              Math.abs(e.clientX - oldX) > 5 ||
              Math.abs(e.clientY - oldY) > 5
            ) {
              _this.uploaderCanClick = false;
            }

            //移动当前元素
            odiv.style.left = left + "px";
            odiv.style.top = top + "px";
          };
          document.onmouseup = () => {
            setTimeout(() => {
              _this.uploaderCanClick = true;
            }, 100);
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      },
    },
  },
  mounted() {
    _this = this;
  },
};
</script>
<style></style>
